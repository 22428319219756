@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --formflex-red: #e00000;
  --formflex-red-hover: #a00202;
  --facebook-color: #6c8bce;
  --twitter-color: #00ACEE;
  --youtube-color: #C4302B;
  --linkedin-color: #0E76A8;
  --instagram-color: #d6249f;
  --white: #fff;
  --gray: #565656;
  --light-gray: #7C7C7C;
  --dark-gray: #303030;
}

* {
  /* Global values */
  font-smooth: inherit;
  font-smooth: initial;
  font-smooth: revert;
  font-smooth: revert-layer;
  font-smooth: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', verdana, sans-serif;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
::-webkit-scrollbar { background: var(--light-gray); -webkit-box-shadow:inset 0 0 5px rgba(0,0,0,.75); /*-webkit-border-radius: 6px;*/ width: 12px; }
::-webkit-scrollbar-thumb { background:var(--formflex-red); -webkit-border-radius: 6px; border: none; -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,.5); width: 12px; }
.content {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.content video {
  width: 100%;
  display: block;
}
.content:before {
  content: '';
  position: absolute;
  background: rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

a:link { text-decoration: none !important; }
a:visited { text-decoration: none !important; }
a:hover { text-decoration: none !important; }
a:active { text-decoration: none !important; }

a~p:link { text-decoration: none !important; }
a~p:visited { text-decoration: none !important; }
a~p:hover { text-decoration: none !important; }
a~p:active { text-decoration: none !important; }

#headerLogo {
  background: url('./images/honestidad.png');
  background-repeat: no-repeat;
  background-position-y: -30px;
}
.adjust{
  margin-top: -35px;
  margin-left: -25px;
}
.titles {
  font-weight: 500;
  font-style: italic;
}
.h2{ font-size: 52px;}
.h3{ font-size: 32px; font-weight: 300;}


/*  jump */
.jump { -webkit-animation: animationFrames 2.9s infinite linear; -o-animation: animationFrames 2.9s infinite linear; -moz-animation: animationFrames 2.9s infinite linear; -ms-animation: animationFrames 2.9s infinite linear; animation: animationFrames 2.9s infinite linear; }
@keyframes animationFrames { 0% { transform: translate(0px,0px); } 17% { transform:  translate(0px,-12px); } 33% { transform: translate(0px,0px); } 50% { transform:  translate(0px,-10px); } 67% { transform: translate(0px,0px); } 84% { transform:  translate(0px,-7px); } 100% { transform: translate(0px,0px); }}
@-moz-keyframes animationFrames { 0% { -moz-transform: translate(0px,0px); } 17% {   -moz-transform: translate(0px,-12px); } 33% { -moz-transform: translate(0px,0px); } 50% { -moz-transform: translate(0px,-10px); } 67% { -moz-transform: translate(0px,0px); } 84% { -moz-transform: translate(0px,-10px); } 100% { -moz-transform:  translate(0px,0px); }}
@-webkit-keyframes animationFrames { 0% { -webkit-transform: translate(0px,0px); } 17% { -webkit-transform: translate(0px,-12px); } 33% { -webkit-transform: translate(0px,0px); } 50% { -webkit-transform: translate(0px,-10px); } 67% { -webkit-transform:  translate(0px,0px); } 84% { -webkit-transform: translate(0px,-10px); } 100% {-webkit-transform: translate(0px,0px); }}
@-o-keyframes animationFrames { 0% {-o-transform: translate(0px,0px); } 17% { -o-transform: translate(0px,-12px); } 33% { -o-transform: translate(0px,0px); } 50% { -o-transform: translate(0px,-10px); } 67% { -o-transform: translate(0px,0px); } 84% { -o-transform: translate(0px,-10px); } 100% { -o-transform: translate(0px,0px); }}
@-ms-keyframes animationFrames { 0% { -ms-transform: translate(0px,0px); } 17% { -ms-transform: translate(0px,-12px); } 33% { -ms-transform: translate(0px,0px); } 50% { -ms-transform:translate(0px,-10px); } 67% { -ms-transform:  translate(0px,0px); } 84% {-ms-transform: translate(0px,-10px); } 100% { -ms-transform:  translate(0px,0px); }}

.foot-top-index {
  background: #e00000;
  border-radius: 50% 50% 0 0;
  bottom: 0;
  padding: 20px 0;
  color: var(--white) !important;
  font-size: 14px;
}

.header, .footer{
  background-color: var(--formflex-red);
}
.banner-index {
  background: url('./images/background-banner-top-index.png') bottom center no-repeat;
}
.maquinas_home{
  height: 150% !important;
}

.uk-navbar-nav>li>a{
  color: var(--white) !important;
  background-image: linear-gradient(rgba(255,255,255,0),rgba(255,255,255,0));
  background-repeat: no-repeat;
  background-position: 0 70%;
  font-size: 1em !important;
}
.uk-navbar-nav>li>a:hover{
  background-image: linear-gradient(rgba(255,255,255,1),rgba(255,255,255,1));
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: 0 70%;
  transition: background-image 1s ease-in-out;
}
.ffx-navbar-dropdown {    
    width: 240px !important;
    padding:0 !important;
    background: var(--dark-gray) !important;
    color: var(--white) !important;
}
.ffx-navbar-dropdown>ul>li>a{
  padding:10px;
  color: var(--white) !important;
  font-size: 12px !important;
}
.ffx-navbar-dropdown>ul>li>a:hover{
  background-image: linear-gradient(rgba(255,255,255,.25),rgba(255,255,255,.25));
}
.switcher_menu>*>a {
  padding-top: 1em !important;
  padding-bottom: 1em !important;
}
.switcher_menu>.uk-active>a {
  color: var(--formflex-red) !important;
  border-color: var(--formflex-red) !important;
}
.uk-button-primary-ffx {
  border: 2px solid var(--formflex-red) !important;  
  color: var(--formflex-red) !important;
  font-weight: 700 !important;
}
.uk-button-primary-ffx:hover {
  background-color:var(--formflex-red);
  border: 2px solid var(--formflex-red) !important;  
  color: var(--white) !important;
  transition: all .35s ease-in-out;
}
.asesor {
  background-image: url('./images/background-soluciones-index.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  height: 480px;
}
.ffx-heading {
  color: var(--formflex-red) !important;
  font-weight: 400;
}
.ffx-heading .h1{
  font-size: 48px !important;
  font-weight: 900 !important;
}
.ffx-heading .h3{
  font-size: 36px;
}
.h1-gray { color: var(--gray) !important;}
.h3-gray { color: var(--gray) !important;}
.ffx-bold { font-weight: 700 !important;}

.sample {
  padding-top: 300px;
}
.calidad {
  background: url(./images/background-tec-index.jpg);
}
.uk-hr, hr {
  overflow: visible;
  text-align: inherit;
  margin: 0 0 20px 0;
  border: 0;
  border-top: 1px solid var(--gray) !important;
}
.footer-top-section {
  background-color: var(--gray);
}
.uk-card-ffx {
  background-color: var(--gray) !important;
  color: var(--white) !important;
}
.uk-text-white{
  color: var(--white) !important;
}
.uk-card-ffx-light{
  background-color: rgba(255,255,255, 0.85);
}
.uk-background-muted {
  background-color: #e6e6e6 !important;
}
.ffx-medium-text {
  font-size: 16px;
  font-weight: 500;
}
/*ETIQUETAS*/
.etiquetasHeader{
  background-image: url('./images/soluciones/etiquetas/soluciones-etiquetas-top.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 65% 100%;
}
/* EMPAQUES*/
.empaquesHeader{
  background-image: url('./images/soluciones/empaques/soluciones-empaques-top.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 65% 100%;
}
/* MANGAS */
.mangasHeader {
  background-image: url('./images/soluciones/mangas/soluciones-mangas-top.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 65% 100%;
}
/* BOLETOS */
.boletosHeader {
  background-image: url('./images/soluciones/boletos/soluciones-boletos-top.png');
  background-repeat: no-repeat;
  background-position: right;
  background-size: 65% 100%;
}
/* INDUSTRIAS */
.industriasHeader {
  background-image: url('./images/industrias/industrias-top-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 400px;
}
/* TECNOLOGIA */
.tecnologiaHeader {
  background-image: url('./images/tecnologia/tecnologia-top.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 400px;
}
/* CONTACTO */
.contactoHeader {
  background-image: url('./images/contacto/contacto-top-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.uk-button-primary.uk-active, .uk-button-primary:active {
  background-color: var(--gray) !important;
  color: #fff;
}
.uk-button-primary {
  background-color: var(--formflex-red) !important;
  color: #fff;
  border: 1px solid transparent;
}
.uk-button-primary:hover {
  background-color: var(--formflex-red-hover) !important;
}