/* (320x480) iPhone (Original, 3G, 3GS) */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    /* insert styles here */
}

/* (320x480) Smartphone, Portrait */

@media only screen and (device-width: 320px) and (orientation: portrait) {
    /* insert styles here */
}

/* (320x480) Smartphone, Landscape */

@media only screen and (device-width: 480px) and (orientation: landscape) {
    /* insert styles here */
}

/* (480x800) Android */

@media only screen and (min-device-width: 480px) and (max-device-width: 800px) {
    /* insert styles here */
}

/* (640x960) iPhone 4 & 4S */

@media only screen and (min-device-width: 640px) and (max-device-width: 960px) {
    /* insert styles here */
}

/* (720x1280) Galaxy Nexus, WXGA */

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
    /* insert styles here */
}

/* (720x1280) Galaxy Nexus, Landscape */

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) and (orientation: landscape) {
    /* insert styles here */
}

/* (1024x768) iPad 1 & 2, XGA */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* insert styles here */
}

/* (768x1024) iPad 1 & 2, Portrait */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* insert styles here */
}

/* (1024x768) iPad 1 & 2, Landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* insert styles here */
}

/* (2048x1536) iPad 3 */

@media only screen and (min-device-width: 1536px) and (max-device-width: 2048px) {
    /* insert styles here */
}

/* (1280x720) Galaxy Note 2, WXGA */

@media only screen and (min-device-width: 720px) and (max-device-width: 1280px) {
    /* insert styles here */
}

/* (1366x768) WXGA Display */

@media screen and (max-width: 1366px) {
    .carousel-h1 {
        white-space: normal;
        font-size: 40px;
        line-height: 60px;
        font-weight: 700;
        color: rgb(248, 195, 0);
        text-transform: uppercase;
    }
    .carousel-p {
        white-space: normal;
        font-size: 35px;
        line-height: 35px;
        font-weight: 300;
        color: rgb(255, 255, 255);
    }
    .uk-text-meta-fd {
        font-size: 18px !important;
    }
    .uk-margin-homeslide {
        margin-left: 7% !important;
        margin-top: -70px !important;
    }
    .uk-toptop-fd {
        bottom: 55px !important;
        right: 25px !important;
    }
    .facebook, .twitter, .youtube {
        width: 24px;
    }
    .error_code {
        font-size: 150px;
    }
    .socialmedia-bar {
        left: 10px !important;
    }
}

@media screen and (max-width: 1366px) and (orientation: portrait) {
    .uk-toptop-fd {
        bottom: 60px !important;
        right: 25px !important;
    }
}

/* (1280x1024) SXGA Display */

@media screen and (max-width: 1280px) {
    /* insert styles here */
}

/* (1440x900) WXGA+ Display */

@media screen and (max-width: 1440px) {
    /* insert styles here */
}

/* (1680x1050) WSXGA+ Display */

@media screen and (max-width: 1680px) {
    /* insert styles here */
}

/* (1920x1080) Full HD Display */

@media screen and (max-width: 1920px) {
    /* insert styles here */
}

/* (1600x900) HD+ Display */

@media screen and (max-width: 1600px) {
    /* insert styles here */
}